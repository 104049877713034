<template>
  <div>
    <div class="banner hardware-banner">
      <div class="banner-item">{{ item }}</div>
    </div>

    <div class="container row-between" style="position: relative">
      <div class="show show-margin" v-if="asideTab == 0">
        <div class="show-title">
          <div class="show-title-t1">智能垃圾分类回收机</div>
          <div class="show-title-p">
            设备所入驻的小区地点皆为居民区人流量大、曝光率高的中心区域，如大门口、主路、广场活动中心等，广告智能触摸屏每天会与用户发生高频的人机交互，广告屏可提供影音、图片等多种形式的广告合作业务。
          </div>
          <div class="show-title-img">
            <img src="../../assets/img/service-2.png" alt="" />
          </div>
        </div>
        <div class="show-title">
          <div class="show-title-t1">智能干湿垃圾回收桶</div>
          <div class="show-title-p">
            此设备专用于收集厨余垃圾、其他垃圾、有害垃圾和可回收垃圾。设备主体由优质不锈钢板及加强筋构成，外盖使用镀锌板件一体成型，并使用了铝合金包边和及塑料包角进行安全防护。表面处理技术为塑粉静电喷涂，可耐高温、防腐蚀、抗氧化，色泽保鲜三年以上。
          </div>
          <div class="show-title-img">
            <img src="../../assets/img//hardware/hardware-s1.png" alt="" />
          </div>
        </div>
        <div class="show-title">
          <div class="show-title-t1">智能垃圾分类回收机</div>
          <div class="show-title-p">
            精灵小松鼠为市政及街道提供美观实用的垃圾厢房改造，迎合垃圾分类是一种新时尚的需求，并且采取多种智能设备结合，使得功能方面能符合居民定时定点投放的需求。
          </div>
          <div class="show-title-img" style="margin: 0">
            <img src="../../assets/img/municipal/municipal-7.png" alt="" />
            <div class="show-title-p" style="margin: 10px 0 20px">
              #1垃圾箱房改造方案
            </div>
          </div>
          <div class="row-between">
            <div class="">
              <div class="show-title-img-sp">
                <img src="../../assets/img/municipal/municipal-8.png" alt="" />
              </div>
              <div class="show-title-p" style="margin: 10px">
                #1改造效果实景图
              </div>
            </div>
            <div class="">
              <div class="show-title-img-sp">
                <img src="../../assets/img/municipal/municipal-6.png" alt="" />
              </div>
              <div class="show-title-p" style="margin: 10px">
                #2改造效果实景图
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="show" style="margin-bottom: 15px" v-if="asideTab == 1">
        <div class="show-title">
          <div class="show-title-t1">智能垃圾分类回收机</div>
          <div class="show-title-p">
            <p>
              1、小松鼠通过在小区、楼宇、学校等公共场所设立智能垃圾分类回收站，采取有偿回收的方式，引导人们主动进行垃圾分类，助力环保事业。
            </p>
            <p>
              2、在服务范围内每个小区将铺设 1 组设备，规模在500户以上铺设 2 组。
            </p>
            <p>
              3、居民可通过小程序二维码或手机号登录，线下投递后可在线上24小时提现。
            </p>
          </div>

          <div class="show-title-img">
            <img src="../../assets/img/service-2.png" alt="" />
          </div>
        </div>
        <div class="show-title">
          <div class="show-title-t1-sp">科技让垃圾分类更时尚、更智能。</div>
          <div class="show-title-p">
            <p>
              1、与传统分类垃圾桶相比，小松鼠智能垃圾分类回收机在外观上更加时尚、美观和洁净，吸引人们的注意力的同时，提升用户的对产品的好感度，进而增加投递频次。
            </p>
            <p>
              2、小松鼠智能垃圾分类回收机融合了多种先进科技，其操作步骤非常便捷，且软件界面十分友好，便于上手。
            </p>
            <p>
              3、老百姓们无处安放的生活垃圾、闲置物品都可以通过小松鼠平台变成真金白银，既环保又赚钱！
            </p>
          </div>

          <div class="show-title-img">
            <img src="../../assets/img/hardware/hardware-s2.png" alt="" />
          </div>
        </div>
        <div class="show-title">
          <div class="show-title-t1-sp">垃圾溯源系统和大数据分析系统。</div>
          <div class="show-title-p">
            <p>
              1、由于传统垃圾厢房在功能和硬件方面都已经不能符合当今政策需求，小松鼠可为各社区垃圾厢房及中转点进行基建改造。传统砖墙结构垃圾房改为时尚美观的现代化建筑。
            </p>
            <p>2、传统的塑料垃圾桶更替为智能回收垃圾桶。</p>
            <p>
              3、增加智能交互、播放的显示器，把垃圾厢房变成推广政令及传播公益的平台。
            </p>
            <p>
              4、增加监控设备及积分系统，协助政府监管居民垃圾分类的完成情况。
            </p>
          </div>

          <div class="show-title-img">
            <img src="../../assets/img/service/service-3.png" alt="" />
          </div>
        </div>
        <div class="show-title">
          <div class="show-title-t1-sp">功能特色</div>
          <div class="show-title-p" style="margin-top: 10px">
            <p>回收机九大特色功能</p>
          </div>

          <div class="show-title-img row-between" style="flex-wrap: wrap">
            <div
              class="show-shadow"
              v-for="item in features"
              :key="item.id"
              :style="'background-image: url(' + item.bgImg + ');'"
            >
              <div class="show-shadow-cont">
                <div class="show-shadow-img">
                  <img :src="item.img" alt="" />
                </div>
                <div class="show-shadow-t1">{{ item.featureName }}</div>
              </div>
              <div class="show-shadow-bg"></div>
            </div>
            <div class="show-shadow"></div>
          </div>
        </div>
        <div class="show-title">
          <div class="show-title-t1-sp">小松鼠用户操作流程</div>
          <div class="show-title-p" style="margin-top: 10px">
            <p>操作简单、投递垃圾实时获取环保金</p>
          </div>

          <div class="show-title-img row-between">
            <div class="operate-sp">
              <div class="operate-sp-img">
                <img src="../../assets/img/hardware/hardware-a1.png" alt="" />
              </div>
              <div class="operate-cont">1.小程序账户登陆</div>
            </div>
            <div class="operate-sp">
              <div class="operate-sp-img">
                <img src="../../assets/img/hardware/hardware-a2.png" alt="" />
              </div>
              <div class="operate-cont">2.搜索回收机位置</div>
            </div>
            <div class="operate-sp">
              <div class="operate-sp-img">
                <img src="../../assets/img/hardware/hardware-a3.png" alt="" />
              </div>
              <div class="operate-cont">3.废品投递</div>
            </div>
            <div class="operate-sp">
              <div class="operate-sp-img">
                <img src="../../assets/img/hardware/hardware-a4.png" alt="" />
              </div>
              <div class="operate-cont">4.环保金收益</div>
            </div>
          </div>
        </div>
        <div class="show-title">
          <div class="show-title-t1-sp">小松鼠垃圾分类回收及处理流程</div>
          <div class="show-title-p" style="margin-top: 10px">
            <p>
              小松鼠设有垃圾分拣中心和再生资源处理中心，实现资源二次利用，减少政府垃圾处理量
            </p>
          </div>
          <div class="show-title-img">
            <img src="../../assets/img/hardware/hardware-s3.png" alt="" />
          </div>
        </div>
      </div>
      <div class="show show-margin" v-if="asideTab == 2">
        <div class="show-title">
          <div class="show-title-t1">智能干湿垃圾回收桶</div>
          <div class="show-title-p">
            设备配置AI语音、人脸识别、二维码识别灯、ic卡多种智能识别方式，厨余垃圾和其他垃圾采用实名制投递，
            结合视频监控系统和管理云平台，实时监测和追溯垃圾来源;设备可选配数字化称重模块，实时检测
            并上报垃圾重量，精准度更高，方便数据采集和分析。全新技术硬核配置和外观设计理念，让产品简
            约时尚而又精致，稳重而不失高雅!
          </div>
          <div class="show-title-img">
            <img src="../../assets/img/hardware/hardware-s1.png" alt="" />
          </div>
        </div>
        <div class="show-title">
          <div class="show-title-t1-sp">功能特色</div>
          <div class="show-title-p" style="margin-top: 10px">
            <p>干湿垃圾回收桶十大特色功能</p>
          </div>

          <div class="show-title-img row-between" style="flex-wrap: wrap">
            <div
              class="show-shadow"
              v-for="item in machineFeatures"
              :key="item.id"
              :style="'background-image: url(' + item.bgImg + ');'"
            >
              <div class="show-shadow-cont">
                <div class="show-shadow-img">
                  <img :src="item.img" alt="" />
                </div>
                <div class="show-shadow-t1">{{ item.featureName }}</div>
              </div>
              <div class="show-shadow-bg"></div>
            </div>
          </div>
        </div>
        <div class="show-title">
          <div class="show-title-t1-sp">功能特色</div>
          <div class="show-title-p" style="margin-top: 10px">
            <p>干湿垃圾回收桶十大特色功能</p>
          </div>

          <div class="show-title-img">
            <img src="../../assets/img/hardware/hardware-s6.png" alt="" />
          </div>
        </div>
      </div>
      <div class="show show-margin" v-if="asideTab == 3">
        <div class="show-title">
          <div class="show-title-t1">智能垃圾箱房</div>
          <div class="show-title-p">
            智能垃圾厢房采用环保高新材料建造，外观时尚美观
            ，完全有别于传统垃圾房脏乱差的形象特征 。该厢
            房集垃圾分类投递、智能化监控、仓储管理于一体，并具有防污、抗腐蚀、除臭、杀菌的功能，所配备的智能回收
            设备和云监控系统可为居民实现 24
            小时无人值守的垃圾投递服务，同时对居民投递行为进行监测，减少人工成本，
            远程满箱预警功能也能确保垃圾桶更换的及时性，保障居民使用便利。
          </div>
          <div class="show-title-img">
            <img src="../../assets/img/municipal/municipal-7.png" alt="" />
          </div>
        </div>
        <div class="show-title">
          <div class="show-title-t1-sp" style="margin-bottom: 0">案例展示</div>
          <div class="show-title-p" style="margin-top: 10px">实景案例展示</div>
          <div class="show-title-p">
            根据社区硬件情况，改建新性智能垃圾厢房取代传统破旧垃圾站站内具备垃圾分类回收、智能管理、除臭杀菌的实用功效外观时髦亲民，真正让垃圾分类成为一种新时尚
          </div>
          <div class="show-title-img row-between">
            <div class="case">
              <div class="case-img">
                <img src="../../assets/img/hardware/hardware-s4.png" alt="" />
              </div>
              <div class="case-cont">
                <div class="row-inline-block" style="margin-right: 6px">
                  <img src="../../assets/img/hardware/icon-error.png" alt="" />
                </div>
                <div class="row-inline-block">社区老旧垃圾房</div>
              </div>
            </div>
            <div class="case">
              <div class="case-img">
                <img src="../../assets/img/municipal/municipal-8.png" alt="" />
              </div>
              <div class="case-cont">
                <div class="row-inline-block" style="margin-right: 6px">
                  <img src="../../assets/img/hardware/icon-right.png" alt="" />
                </div>
                <div class="row-inline-block">智能社区垃圾房</div>
              </div>
            </div>
            <div class="case">
              <div class="case-img">
                <img src="../../assets/img/municipal/municipal-6.png" alt="" />
              </div>
              <div class="case-cont">
                <div class="row-inline-block" style="margin-right: 6px">
                  <img src="../../assets/img/hardware/icon-right.png" alt="" />
                </div>
                <div class="row-inline-block">全品类垃圾智能垃圾厢</div>
              </div>
            </div>
            <div class="case">
              <div class="case-img">
                <img src="../../assets/img/hardware/hardware-s5.png" alt="" />
              </div>
              <div class="case-cont">
                <div class="row-inline-block" style="margin-right: 6px">
                  <img src="../../assets/img/hardware/icon-right.png" alt="" />
                </div>
                <div class="row-inline-block">智能垃圾厢房改造设计</div>
              </div>
            </div>
          </div>
        </div>
        <div style="height: 10px"></div>
      </div>
      <div class=""></div>
      <div class="aside-nav" :style="top">
        <div class="aside-nav-li aside-nav-li-sp row-start">
          <div>硬件产品</div>
          <div class="aside-nav-sp-t1">精灵小松鼠</div>
        </div>
        <div
          class="aside-nav-li row-between"
          v-for="item in asideNav"
          :key="item.id"
          :class="asideTab == item.id ? 'aside-nav-li-active' : ''"
          @click="asideChange(item.id)"
        >
          <div class="aside-nav-t1">{{ item.name }}</div>
          <div class="aside-nav-t2">
            <img src="../../assets/img/arrow-line.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      top: "",
      item: "硬件产品",
      asideNav: [
        {
          id: 0,
          name: "硬件介绍",
          img: "",
        },
        {
          id: 1,
          name: "智能垃圾分类回收机",
          img: "",
        },
        {
          id: 2,
          name: "智能干湿垃圾回收桶",
          img: "",
        },
        {
          id: 3,
          name: "智能垃圾箱房",
          img: "",
        },
      ],
      asideTab: 0,
      features: [
        {
          id: 0,
          img: require("../../assets/img/hardware/hardware-c1-icon.png"),
          bgImg: require("../../assets/img/hardware/hardware-c1.png"),
          featureName: "设备定位",
        },
        {
          id: 1,
          img: require("../../assets/img/hardware/hardware-c2-icon.png"),
          bgImg: require("../../assets/img/hardware/hardware-c2.png"),
          featureName: "身份识别",
        },
        {
          id: 2,
          img: require("../../assets/img/hardware/hardware-c3-icon.png"),
          bgImg: require("../../assets/img/hardware/hardware-c3.png"),
          featureName: "投递识别",
        },
        {
          id: 3,
          img: require("../../assets/img/hardware/hardware-c4-icon.png"),
          bgImg: require("../../assets/img/hardware/hardware-c4.png"),
          featureName: "智能称重",
        },
        {
          id: 4,
          img: require("../../assets/img/hardware/hardware-c5-icon.png"),
          bgImg: require("../../assets/img/hardware/hardware-c5.png"),
          featureName: "满箱预警",
        },
        {
          id: 5,
          img: require("../../assets/img/hardware/hardware-c6-icon.png"),
          bgImg: require("../../assets/img/hardware/hardware-c6.png"),
          featureName: "智能消毒",
        },
        {
          id: 6,
          img: require("../../assets/img/hardware/hardware-c7-icon.png"),
          bgImg: require("../../assets/img/hardware/hardware-c7.png"),
          featureName: "智能防夹",
        },
        {
          id: 7,
          img: require("../../assets/img/hardware/hardware-c8-icon.png"),
          bgImg: require("../../assets/img/hardware/hardware-c8.png"),
          featureName: "温度检测",
        },
        {
          id: 8,
          img: require("../../assets/img/hardware/hardware-c9-icon.png"),
          bgImg: require("../../assets/img/hardware/hardware-c9.png"),
          featureName: "智能照明",
        },
      ],
      machineFeatures: [
        {
          id: 0,
          img: require("../../assets/img/hardware/hardware-d1-icon.png"),
          bgImg: require("../../assets/img/hardware/hardware-d1.png"),
          featureName: "嵌入式中控系统",
        },
        {
          id: 1,
          img: require("../../assets/img/hardware/hardware-d2-icon.png"),
          bgImg: require("../../assets/img/hardware/hardware-d2.png"),
          featureName: "智能电表",
        },
        {
          id: 2,
          img: require("../../assets/img/hardware/hardware-d3-icon.png"),
          bgImg: require("../../assets/img/hardware/hardware-d3.png"),
          featureName: "智能喷淋系统",
        },
        {
          id: 3,
          img: require("../../assets/img/hardware/hardware-d4-icon.png"),
          bgImg: require("../../assets/img/hardware/hardware-d4.png"),
          featureName: "时控开关",
        },
        {
          id: 4,
          img: require("../../assets/img/hardware/hardware-d5-icon.png"),
          bgImg: require("../../assets/img/hardware/hardware-d5.png"),
          featureName: "臭氧杀菌功能模块",
        },
        {
          id: 5,
          img: require("../../assets/img/hardware/hardware-d6-icon.png"),
          bgImg: require("../../assets/img/hardware/hardware-d6.png"),
          featureName: "厨余垃圾破袋锯齿",
        },
        {
          id: 6,
          img: require("../../assets/img/hardware/hardware-d7-icon.png"),
          bgImg: require("../../assets/img/hardware/hardware-d7.png"),
          featureName: "不锈钢桶身",
        },
        {
          id: 7,
          img: require("../../assets/img/hardware/hardware-d8-icon.png"),
          bgImg: require("../../assets/img/hardware/hardware-d8.png"),
          featureName: "数字化称重模块",
        },
        {
          id: 8,
          img: require("../../assets/img/hardware/hardware-d9-icon.png"),
          bgImg: require("../../assets/img/hardware/hardware-d9.png"),
          featureName: "外挂式有害分类桶",
        },
        {
          id: 9,
          img: require("../../assets/img/hardware/hardware-d10-icon.png"),
          bgImg: require("../../assets/img/hardware/hardware-d10.png"),
          featureName: "设备稳压系统",
        },
      ],
    };
  },

  mounted: function () {
    this.asideTab = 0;
    window.addEventListener("scroll", this.scrollToTop, true);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollToTop, true);
  },
  methods: {
    scrollToTop() {
      var asideTab = this.asideTab;
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      console.log(scrollTop);
      switch (asideTab) {
        case 0:
          if (scrollTop > 200 && scrollTop < 2400) {
            this.top = "top:" + (scrollTop * 1 - 200) + "px";
          } else if (scrollTop > 2400) {
            this.top = "top:2200px";
          } else {
            this.top = 0;
          }
          break;
        case 1:
          if (scrollTop > 200 && scrollTop < 3700) {
            this.top = "top:" + (scrollTop * 1 - 200) + "px";
          } else if (scrollTop > 3700) {
            this.top = "top:3500px";
          } else {
            this.top = 0;
          }
          break;
        case 2:
          if (scrollTop > 200 && scrollTop < 1400) {
            this.top = "top:" + (scrollTop * 1 - 200) + "px";
          } else if (scrollTop > 1400) {
            this.top = "top:1200px";
          } else {
            this.top = 0;
          }
          break;
        case 3:
          if (scrollTop > 200 && scrollTop < 1000) {
            this.top = "top:" + (scrollTop * 1 - 200) + "px";
          } else if (scrollTop > 1000) {
            this.top = "top:800px";
          } else {
            this.top = 0;
          }
          break;
      }
    },
    asideChange(e) {
      this.asideTab = e;
    },
  },
};
</script>

<style>
.hardware-banner {
  background-image: url("../../assets/img/hardware/hardware-banner.png");
}
.operate-sp {
  width: 199px;
  text-align: center;
}
.operate-sp-img {
  width: 199px;
  height: 148px;
  background: #dde8f4;
  margin-bottom: 10px;
}
.case {
  width: 199px;
  text-align: center;
}
.case-img {
  width: 199px;
  height: 148px;
  margin-bottom: 12px;
}
</style>